import { useState, useEffect } from "react";
import axios from 'axios';

const CategoryForm = ({ addCategory, updateCategory, category, onClose }) => {
    const [formData, setFormData] = useState({
        name: category ? category.name : '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const API_URL = process.env.API_URL || 'http://localhost:3001';

    useEffect(() => {
        if (category) {
            setFormData({ name: category.name });
        }
    }, [category]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.name.length === 0) {
            setErrorMessage('Champ obligatoire.');
            return;
        }
        if (formData.name.length < 2) {
            setErrorMessage('Le nom doit contenir au moins 2 caractères.');
            return;
        }
        if (formData.name.length > 20) {
            setErrorMessage('Le nom ne doit pas dépasser 20 caractères.');
            return;
        }

        try {
            if (category) {
                const response = await axios.put(`${API_URL}/forum/categories/${category._id}`, formData);
                updateCategory(response.data);
                onClose(true, true);
            } else {
                const response = await axios.post(`${API_URL}/forum/categories`, formData);
                addCategory(response.data);
                onClose(false, true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <dialog id={category ? 'edit_category_modal' : 'category_modal'} className="modal">
            <div className="modal-box bg-neutral text-white">
                <form method="dialog" className="absolute right-2 top-2" onClick={onClose}>
                    <button className="btn btn-sm btn-circle btn-ghost text-white">✕</button>
                </form>
                <h3 className="font-bold text-lg text-white">{category ? 'Modifier' : 'Ajouter'} une catégorie</h3>
                <form onSubmit={handleSubmit} className="mt-4">
                    <label htmlFor="name" className="block font-semibold text-white">
                        Nom*
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Entrez le nom de la catégorie"
                        value={formData.name}
                        onChange={handleChange}
                        className="input input-bordered w-full mt-2 bg-neutral text-white"
                        required
                    />
                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                    <div className="modal-action">
                        <button type="submit" className="btn btn-primary font-bold">
                            {category ? 'Mettre à jour' : 'Ajouter'}
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    );
};

export default CategoryForm;
