import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";


const Home = () => {


    const {authUser, setAuthUser} = useContext(AuthContext)

    return (
        <div>
            Connecté en tant que <span>{authUser.username}</span>
        </div>
    )
}

export default Home