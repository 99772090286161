import {Navigate, Route, Routes} from "react-router-dom"
import Presentation from "./pages/Presentation"
import Login from "./pages/Login"
import Inscription from "./pages/Inscription";
import Home from "./pages/Home"
import ValidationEmail from "./pages/ValidationEmail";
import Error from "./pages/Error"
import Forum from "./components/Forum";
import Category from "./pages/Category";

const App = () => {
  return (
      <Routes>
          <Route path="/" element={<Presentation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Inscription />} />
          <Route path="/home" element={<Home />} />
          <Route path="/validate-email" element={<ValidationEmail/>}/>

              <Route path="/forum" element={<Forum />} />
              <Route path="/forum/categories/:categoryId" element={<Category />} />

          {/* Routes pour les pages d'erreur */}
          <Route path="/400" element={<Error code={400}/>} />
          <Route path="/401" element={<Error code={401}/>} />
          <Route path="/403" element={<Error code={403}/>} />
          <Route path="/404" element={<Error code={404}/>} />
          <Route path="/409" element={<Error code={409}/>} />
          <Route path="/422" element={<Error code={422}/>} />
          <Route path="/500" element={<Error code={500}/>} />

          {/* Routes pour les pages inexistantes */}
          <Route path="/*" element={<Navigate to="/404"/>} />
      </Routes>
  )
}

export default App