import {Link, useNavigate} from "react-router-dom"
import {MdLogin} from "react-icons/md"
import React, {useContext} from "react"
import {AuthContext} from "../contexts/AuthContext"


const PresentationHeader = () => {
    const navigate = useNavigate()

    const {authUser} = useContext(AuthContext)

    return (
        <header>
            <div className="presentation-header bg-neutral">
                <h1><Link className="pixel-font" to={'/'}>RetroRealm</Link></h1>
                {!authUser && (
                    <button className="btn btn-primary btn-circle btn-icon" onClick={() => {
                        navigate("/login")
                    }}>
                        <MdLogin size={32}/>
                    </button>
                )}
            </div>
        </header>
    )
}

export default PresentationHeader