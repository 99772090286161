import React, {useContext, useEffect, useState} from "react"
import Footer from "../components/Footer"
import {Link, useNavigate} from "react-router-dom";

import { MdLogin } from "react-icons/md";
import axios from "axios";
import {AuthContext} from "../contexts/AuthContext";
import PresentationHeader from "../components/PresentationHeader";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'

const ValidationEmail = () => {
    const {authUser, setAuthUser} = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Validation | RetroRealm"
        if (authUser.isAccountValidated) {
            navigate("/home")
        }
        if (!localStorage.getItem("auth-user")) {
            navigate("/")
        }
        generateEmailCode()
    }, [])

    const [emailSent, setEmailSent] = useState(false)
    const [codeError, setCodeError] = useState("");
    const [code, setCode] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCode(value);
    }

    const generateEmailCode = async () => {
        setEmailSent(true)
        await axios.post(`${API_URL}/auth/generate-code`, {},
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("auth-user"))}` } });
    }

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API_URL}/auth/validate-email`, {code: code},
                { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("auth-user"))}` } });
            if (!response.data.error) {
                if (response.data.validation) {
                    navigate("/home")
                } else {
                    console.log("GESTION ERREUR")
                }
            }
        } catch (error) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("auth-user")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    const errors = error.response.data.errors
                    setCodeError(errors)
                    break
                default:
                    navigate("/500")
            }
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <PresentationHeader/>
            <div className="flex-grow flex justify-center items-center">
                <div className="rounded-3xl bg-base-300 w-1/3 p-10 flex flex-col justify-center items-center">
                    <h1 className="text-2xl mb-4 text-center">Validation par courriel</h1>

                    {emailSent ? (
                        <>
                            <p className="text-center">
                                Un courriel contenant un code de validation à été envoyé à l'adresse <span className="font-bold text-white">{authUser.email}</span>
                            </p>

                            <p  className="text-center">Entrez le code pour valider votre compte.</p>
                            <form onSubmit={submitForm} className="w-full">
                                <label className="form-control block w-full">
                                    <div className="label">
                                        <span className="label-text text-base-300 text-lg">Nom d'utilisateur</span>
                                    </div>
                                    <input name="validation-code" type="text" placeholder="Code" value={code}
                                           onChange={handleChange}
                                           className="input input-se input-bordered w-full rounded-full bg-gray-200 text-black block mb-8"/>
                                </label>
                                <div className="flex flex-col gap-4 items-center">
                                    <input type="submit" className="btn btn-primary rounded-full w-1/3" value="Vérifier"/>
                                    <button type="button" className="btn btn-secondary rounded-full w-1/3"
                                            onClick={generateEmailCode}>Envoyer
                                        un
                                        autre code
                                    </button>
                                </div>
                            </form>

                        </>
                    ) : (
                        <button className="btn btn-primary rounded-full" onClick={generateEmailCode}>Envoyer un
                            code</button>
                    )}

                    <Link to="/home" className="link link-primary block text-center text-lg mt-8">Accueil</Link>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ValidationEmail