import React from "react";
import { IoIosArrowUp } from "react-icons/io";

const Footer = () => {
    return (
        <>
            <footer className="bg-neutral">
                <div className="container">
                    <button onClick={() => {window.scroll(0, 0)}} className="btn btn-primary btn-circle btn-icon"><IoIosArrowUp size={32}/></button>
                    <p>Copyright © 2024 - Tous droits réservés par Adam Demers, Loïc Boiteux, Samuel Lavoie et Keven Ouellet</p>
                    <div>
                        <a className="btn btn-secondary btn-circle" href="https://www.instagram.com/grindr/"
                           target="_blank" rel="noreferrer"></a>
                        <a className="btn btn-secondary btn-circle" href="https://www.facebook.com/Grindr/"
                           target="_blank" rel="noreferrer"></a>
                        <a className="btn btn-secondary btn-circle" href="https://twitter.com/grindr" target="_blank"
                           rel="noreferrer"></a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer